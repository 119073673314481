@charset "utf-8";

body {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #3d4e52;
}

video,
iframe {
    display: block;
}

ul,
ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

p {
    margin: 0;
    font-size: 23px;
    line-height: 30px;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a:focus,
button:focus {
    outline: 0;
    box-shadow: 0 0;
}

/* hussain css */

/* header section css */
.header-sec {
    position: relative;
    z-index: 2;
}

.top-nav {
    background: #f4f4f4;
    padding: 10px 0;
}

.contact-no p {
    color: #07151f;
    font-size: 12px;
}

.contact-no p strong {
    color: #07151f;
    font-size: 12px;
    font-weight: 600;
}

.socl-top ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.socl-top ul li a:hover img {
    opacity: 0.8;
}

.banner-sec img {
    width: 100%;
}

.header_iner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
}

.btn.btn-donet {
    background: #8ca720;
    padding: 5px 14px;
    text-align: center;
    color: #fff;
    border-radius: 25px;
    order: 2;
    font-size: 14px;
}

.newsltr button:hover,
.btn.btn-redmor:hover,
.btn.btn-donet:hover {
    background: #5fa71f;
    color: #fff;
}

.logo {
    position: relative;
}

.logo a {
    /* position: absolute;
    top: -40px; */
    width: 220px;
    display: block;
    cursor: pointer;
}

.logo a img {
    width: 100%;
}

/*=========menu css===========*/
#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #menu-button {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
}

#cssmenu:after,
#cssmenu>ul:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

#cssmenu #menu-button {
    display: none;
}

#cssmenu {
    width: auto;
    line-height: 1;
    display: flex;
    gap: 10px;
    align-items: center;
}

#menu-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    background: #191818;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -ms-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    display: none;
}

#cssmenu>ul>li {
    float: left;
}

#cssmenu.align-center>ul {
    font-size: 0;
    text-align: center;
}

#cssmenu.align-center>ul>li {
    display: inline-block;
    float: none;
}

#cssmenu.align-center ul ul {
    text-align: left;
}

#cssmenu.align-right>ul>li {
    float: right;
}

#cssmenu.align-right ul ul {
    text-align: right;
}

#cssmenu>ul>li>a {
    padding: 0px 22px;
    font-size: 14px;
    line-height: 90px;
    font-weight: 500;
    color: #061b23;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: color .2s ease;
    -moz-transition: color .2s ease;
    -ms-transition: color .2s ease;
    -o-transition: color .2s ease;
    transition: color .2s ease;
    overflow: hidden;
    border-radius: 0;
}

#cssmenu>ul>li>a::before {
    /* background: url("../img/arrow-green.png") center center no-repeat; */
    content: "";
    position: absolute;
    width: 17px;
    height: 12px;
    top: 13px;
    transition: all 0.45s ease 0s;
    left: -50px;
    background-size: 100%;
}

#cssmenu>ul>li>a:hover::before {
    left: 0px;
}

#cssmenu>ul>li>a.active::before {
    display: none !important;
}

#cssmenu>ul>li>a:hover,
#cssmenu>ul>li>a.active {
    background-color: #ff5a00;
    color: #fff;
}

#cssmenu>ul>li:last-child a::before {
    display: none;
}

#cssmenu>ul>li.has-sub>a {
    padding-right: 25px;
}

#cssmenu>ul>li.has-sub>a::after {
    position: absolute;
    top: 41px;
    right: 10px;
    width: 6px;
    height: 6px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    content: "";
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: border-color 0.2s ease;
    -moz-transition: border-color 0.2s ease;
    -ms-transition: border-color 0.2s ease;
    -o-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
}

#cssmenu>ul>li>a.nav-pills {
    --bs-nav-pills-link-active-bg: #191818;
}

#cssmenu>ul>li.has-sub>a.active::after,
#cssmenu>ul>li.has-sub:hover>a::after {
    border-color: #fff;
}

#cssmenu ul ul {
    position: absolute;
    left: -9999px;
    background: #ff5a00;
    width: 310px;
    border-radius: 0;
}

#cssmenu li:hover>ul {
    left: auto;
}

#cssmenu.align-right li:hover>ul {
    right: 0;
}

#cssmenu ul ul ul {
    margin-left: 100%;
    top: 0;
}

#cssmenu.align-right ul ul ul {
    margin-left: 0;
    margin-right: 100%;
}

#cssmenu ul ul li {
    height: 0;
    -webkit-transition: height .2s ease;
    -moz-transition: height .2s ease;
    -ms-transition: height .2s ease;
    -o-transition: height .2s ease;
    transition: height .2s ease;
}

#cssmenu ul li:hover>ul>li {
    height: auto;
}

#cssmenu ul ul ul li a,
#cssmenu ul ul li a {
    font-size: 18px;
    padding: 14px 20px;
    text-decoration: none;
    color: #fff;
    -webkit-transition: color .2s ease;
    -moz-transition: color .2s ease;
    -ms-transition: color .2s ease;
    -o-transition: color .2s ease;
    transition: color .2s ease;
    border-bottom: 1px solid #eee;
    border-radius: 0 !important;
    /* text-transform: capitalize; */
}


#cssmenu ul ul li:last-child a {
    border: 0 !important;
}

#cssmenu ul ul li:hover>a,
#cssmenu ul ul li a:hover {
    color: #fff;
}

#cssmenu ul ul li.has-sub>a::after {
    position: absolute;
    top: 13px;
    right: 10px;
    width: 4px;
    height: 4px;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    content: "";
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: border-color 0.2s ease;
    -moz-transition: border-color 0.2s ease;
    -ms-transition: border-color 0.2s ease;
    -o-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
}

#cssmenu.align-right ul ul li.has-sub>a::after {
    right: auto;
    left: 10px;
    border-bottom: 0;
    border-right: 0;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
}

#cssmenu ul ul li.has-sub:hover>a::after {
    border-color: #ffffff;
}

/* end */

/* banner css */
#baner_caro {
    position: relative;
}

#baner_caro .owl-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
}

#baner_caro .owl-dots .owl-dot.active span {
    background: rgba(250, 250, 250, 1);
}

#baner_caro .owl-dots .owl-dot {
    margin: 0 2px;
}

#baner_caro .owl-dots .owl-dot span {
    width: 14px;
    height: 14px;
    background: rgba(250, 250, 250, 0.2);
    border-radius: 50%;
    display: block;
}

.gmab {
    color: #ff5a00;
}

.ban-cont {
    position: absolute;
    bottom: -50%;
    width: 100%;
    text-align: center;
    transition: all 0.45s ease 0s;
    opacity: 0;
}

.active .ban-cont {
    bottom: 20%;
    transition-delay: 0.9s;
    opacity: 1;
}

.ban-cont strong {
    color: #fff;
    font-size: 50px;
    line-height: 80px;
    font-weight: 500;
    display: block;
}

.ban-cont p {
    color: #fff;
    font-size: 35px;
    line-height: 40px;
    font-weight: 300;
    margin-top: 20px;
}

/* category css */
.catgry-sec-DIV h3 {
    color: #1b292c;
    font-size: 19px;
    line-height: 23px;
    font-weight: 300;
    margin-top: 30px;
}

.catgry-sec-DIV h3 strong {
    color: #1b292c;
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
    display: block;
}

.catgry-sec-DIV p {
    color: #3d4e52;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 20px;
}

.catgry-sec-DIV.abouts strong {
    color: #fff;
    font-size: 60px;
    line-height: 66px;
    font-weight: 300;
    display: block;
}

.catgry-sec-DIV.abouts p {
    color: #fff;
    font-size: 36px;
    line-height: 38px;
    font-weight: 100;
    margin-top: 20px;
}

.catgry-sec-inr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.catgry-sec-DIV {
    width: 25%;
    background: #eee;
    padding: 20px;
}

.catgry-sec-DIV.abouts,
.catgry-sec-DIV.active,
.catgry-sec-DIV:hover {
    background: #ff5a00;
}

.catgry-sec-DIV:hover span img {
    filter: brightness(0) invert(1);
}

.catgry-sec-DIV:hover strong,
.catgry-sec-DIV:hover p,
.catgry-sec-DIV:hover h3 {
    color: #fff;
}

.catgry-sec-DIV.img-Div {
    padding: 0;
}

.catgry-sec-DIV.img-Div img {
    width: 100%;
    height: 100%;
}

.catgry-sec-DIV.abouts .spn-arro {
    margin-left: 0;
    filter: brightness(0) invert(1);
}

.spn-arro {
    background: url("../img/black-aro.png") 0 0 no-repeat;
    width: 35px;
    height: 19px;
    display: block;
    margin-top: 50px;
    margin-left: auto;
}

.catgry-sec-DIV:hover .spn-arro {
    background: url("../img/wht-aro.png") 0 0 no-repeat;
}

/* welcome sec css */
.partnr-sec,
.welcome-sec {
    padding: 40px 0;
}

.btn.btn-redmor {
    background: #8ca720;
    padding: 5px 14px;
    text-align: center;
    color: #fff;
    border-radius: 25px;
    order: 2;
    font-size: 14px;
}

.welcome-sec-inr {
    text-align: center;
}

.welcome-sec-inr h3 {
    color: #1b292c;
    font-size: 32px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 20px;
}

.welcome-sec-inr h3 span {
    color: #ff5a00;
}

.welcome-sec-inr p {
    color: #3d4e52;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 20px;
}


/* Media & News Sec Css */
.media-news-inner {
    display: flex;
}

.media-news-left {
    background: #ff5a00;
    padding: 100px 40px 30px;
    width: 20%;
    /* text-align: center; */
}

.video-play-button {
    box-sizing: content-box;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 20px;
    margin: 0 auto;
    position: relative;
}

.video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #ff5a00;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #ff5a00;
    border-radius: 50%;
    transition: all 200ms;
}

.video-play-button:hover:after {
    background-color: darken(#ff5a00, 10%);
}

.video-play-button img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
}

.video-play-button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid #fff;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    left: 12px;
    top: 2px;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

.hom-vdeo.modal-header {
    padding: 0;
    border: 0;
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 1;
}

.hom-vdeo-body.modal-body {
    padding: 0;
}

.media-news-left h4 {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    margin: 20px 0 50px;
}

.histry-cont .values li {
    margin: 0;
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
    background: #fff;
}

.media-news-left h4 strong {
    color: #fff;
    font-size: 30px;
    line-height: 34px;
    font-weight: 400;
    display: block;
}

.media-news-left .btn.btn-vwall {
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-decoration: underline;
}

.media-news-DIV {
    background: #eaeaea;
    padding: 20px;
}

.media-news-DIV:hover {
    background: #f4f4f4;
}

#media_caro {
    width: 80%;
}

.media-news-DIV strong {
    color: #1b292c;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: block;
    margin: 20px 0;
    min-height: 76px;
    text-align: center;
}

.media-news-DIV p {
    color: #3d4e52;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

#media_caro .owl-nav {
    position: absolute;
}

#media_caro.owl-carousel .owl-stage,
#media_caro.owl-carousel .owl-stage-outer {
    position: inherit;
}

#media_caro .owl-nav {
    position: absolute;
    left: -70px;
    bottom: 36px;
}

#media_caro .owl-nav .owl-prev span {
    background: url("../img/white-aro-l.PNG") 0 0 no-repeat;
    width: 22px;
    height: 19px;
    display: block;
    font-size: 0;
}

#media_caro .owl-nav .owl-next span {
    background: url("../img/white-aro-r.png") 0 0 no-repeat;
    width: 22px;
    height: 19px;
    display: block;
    font-size: 0;
}

#media_caro .owl-nav .owl-prev,
#media_caro .owl-nav .owl-next {
    margin: 0 4px;
}

.media-news-DIV .btn {
    background: url("../img/black-aro.png") 0 0 no-repeat;
    width: 35px;
    height: 19px;
    display: block;
    font-size: 0;
    margin: 10px 0 10px auto;
}


/* Video Sec Css */
.hom-vdeo-body.modal-body video {
    width: 100%;
    height: auto;
}

.hom-vdeo.modal-header {
    border: 1px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.hom-vdeo.modal-header .btn-close {
    padding: 0;
    filter: brightness(0) invert(1);
    font-size: 18px;
    background-size: 10px;
    width: 10px;
    height: 10px;
    margin: 0 auto;
}

.video-sec {
    position: relative;
}

.video-sec-img img {
    width: 100%;
}

.video-sec-content {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
}

.video-sec-content p {
    color: #fff;
    font-size: 36px;
    line-height: 40px;
    font-weight: 400;
    margin: 50px auto;
    width: 54%;
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: .8;
    filter: alpha(opacity=80)
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: 0 !important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.mfp-align-top .mfp-container:before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none !important
}

.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

.mfp-preloader a {
    color: #ccc
}

.mfp-preloader a:hover {
    color: #fff
}

.mfp-s-error .mfp-content,
.mfp-s-ready .mfp-preloader {
    display: none
}

button.mfp-arrow,
button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    filter: alpha(opacity=65);
    padding: 0 0 18px 10px;
    color: #fff;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace
}

.mfp-close:focus,
.mfp-close:hover {
    opacity: 1;
    filter: alpha(opacity=100)
}

.mfp-close:active {
    top: 1px
}

.mfp-close-btn-in .mfp-close {
    color: #333
}

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-arrow {
    position: absolute;
    opacity: .65;
    filter: alpha(opacity=65);
    margin: -55px 0 0;
    top: 50%;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:focus,
.mfp-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100)
}

.mfp-arrow .mfp-a,
.mfp-arrow .mfp-b,
.mfp-arrow:after,
.mfp-arrow:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: inset transparent
}

.mfp-arrow .mfp-a,
.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px
}

.mfp-arrow .mfp-b,
.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: .7
}

.mfp-arrow-left {
    left: 0
}

.mfp-arrow-left .mfp-a,
.mfp-arrow-left:after {
    border-right: 17px solid #fff;
    margin-left: 31px
}

.mfp-arrow-left .mfp-b,
.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f
}

.mfp-arrow-right {
    right: 0
}

.mfp-arrow-right .mfp-a,
.mfp-arrow-right:after {
    border-left: 17px solid #fff;
    margin-left: 39px
}

.mfp-arrow-right .mfp-b,
.mfp-arrow-right:before {
    border-left: 27px solid #3f3f3f
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #000
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0;
    margin: 0 auto
}

.mfp-figure {
    line-height: 0
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #444
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-figure figure {
    margin: 0
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}


#cssmenu .submenu-button {
    display: none;
}

@media screen and (max-width:800px) and (orientation:landscape),
screen and (max-height:300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width:900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

.mfp-ie7 .mfp-img {
    padding: 0
}

.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px
}

.mfp-ie7 .mfp-container {
    padding: 0
}

.mfp-ie7 .mfp-content {
    padding-top: 44px
}

.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0
}

.tile__img {
    cursor: pointer;
}


/* Partner sec css */
#gallery {
    margin: 1rem;
}

#gallery img {
    margin: .2rem;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: ''
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.slide {
    text-align: center;
}

.slide img {
    display: block;
    margin: 0 auto !important;
}

.partnr-sec-inr h3 {
    font-size: 24px;
    color: #1b292c;
    line-height: 28px;
    margin-bottom: 40px;
    text-align: center;
}

/* Tespominal Sec Css */
.tstimo-sec-top {
    background: url("../img/testimonl-bg.png") center center no-repeat;
    background-size: cover;
}

.tstimo-sec-top .container {
    display: flex;
    justify-content: flex-end;
}

.tstimo-sec-txt {
    padding: 80px 0;
    width: 43%;
}

.tstimo-sec-txt h4 {
    font-size: 40px;
    color: #FFF;
    line-height: 46px;
    margin-bottom: 40px;
    font-weight: 300;
}

.tstimo-sec-txt strong {
    font-size: 20px;
    color: #FFF;
    line-height: 28px;
    margin-bottom: 30px;
    display: block;
    font-weight: 400;
}

.tstimo-sec-txt p {
    font-size: 14px;
    color: #808c8e;
    line-height: 22px;
    margin-bottom: 40px;
    font-weight: 400;
}

.tstimo-sec-blo-rit strong {
    font-size: 22px;
    color: #1b292c;
    line-height: 26px;
    display: block;
    font-weight: 300;
}

.tstimo-sec-blo-rit h4 {
    font-size: 36px;
    color: #1b292c;
    line-height: 38px;
    display: block;
    font-weight: 400;
}

.tstimo-sec-blo-rit span {
    background: url("../img/smil-icn.png") center center no-repeat;
    width: 65px;
    height: 30px;
    position: relative;
    right: -340px;
    display: block;
    bottom: 10px;
}

.tstimo-Div p {
    font-size: 14px;
    color: #647174;
    line-height: 26px;
    font-weight: 400;
    display: block;
    margin-bottom: 20px;
}

.tstimo-Div strong {
    font-size: 16px;
    color: #19272a;
    line-height: 22px;
    font-weight: 500;
    display: block;
}

.tstimo-Div span {
    font-size: 12px;
    color: #5d7074;
    line-height: 14px;
    font-weight: 400;
    display: block;
}

.tstimo-sec-blo .container {
    display: flex;
    justify-content: space-between;
}

.tstimo-sec-blo-left {
    width: 50%;
    margin: -244px 0 0;
}

.tstimo-sec-blo-rit {
    width: 40%;
}

span.tst-icn {
    display: block;
    margin: 0 auto 30px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}

.owl-carousel .owl-item span.tst-icn img {
    height: 100%;
    width: auto;
}

.tstimo-sec-blo-left h3 {
    font-size: 50px;
    color: #fff;
    line-height: 56px;
    font-weight: 300;
    margin-bottom: 40px;
}

.tstimo-sec-blo {
    background: #f4f4f4;
    padding: 40px 0;
}

.tstimo-sec-blo-left h3 span {
    font-size: 30px;
    color: #fff;
    line-height: 34px;
    font-weight: 100;
    display: block;
}

#tstimo_caro .owl-nav {
    position: absolute;
    right: 30px;
    bottom: 40px;
}

#tstimo_caro .owl-nav .owl-prev span {
    background: url("../img/org-aro-l.png") 0 0 no-repeat;
    width: 35px;
    height: 19px;
    display: block;
    font-size: 0;
}

#tstimo_caro .owl-nav .owl-next span {
    background: url("../img/org-aro-r.png") 0 0 no-repeat;
    width: 35px;
    height: 19px;
    display: block;
    font-size: 0;
}

#tstimo_caro .owl-nav .owl-prev,
#tstimo_caro .owl-nav .owl-next {
    margin: 0 4px;
}

.tstimo-sec-form .btn.btn-redmor {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
}

.tstimo-sec-form .form-select,
.tstimo-sec-form .form-control {
    border-color: #ff5a00;
    border-radius: 0;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
}

.tstimo-sec-form {
    margin-top: 40px;
}

#tstimo_caro {
    padding: 80px 50px;
    background: #fff;
}

.tstimo-Div article {
    margin-top: 40px;
}

.media-news-sec {
    border-bottom: 2px solid #fff;
}

/* Footer Sec Css */
.foot-top-sec {
    background: #192426;
    padding: 60px 0;
}

.copy-rit a {
    color: #B6B6B6;
}

.copy-rit a:hover {
    text-decoration: underline;
}

.copy-rit-socl {
    background: #121a1b;
    padding: 12px 0;
    color: #B6B6B6;
    font-size: 12px;
    font-weight: 400;
}

.copy-rit-socl .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-sec {
    overflow: hidden;
}

.foot-socl {
    display: flex;
    align-items: center;
    gap: 10px;
    order: 2;
}

.foot-socl ul {
    display: flex;
    gap: 3px;
}

.foot_mnu_l {
    width: 50%;
}

.footer-sec_inr {
    display: flex;
    justify-content: space-between;
}

.logo-foot {
    width: 25%;
}

.foot_mnu {
    width: 70%;
    display: flex;
}

.foot_mnu_l ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.foot_mnu_l ul li {
    width: 40%;
}

.orgns-div img {
    width: auto;
}

.foot_mnu_l ul li a {
    color: #B6B6B6 !important;
    font-size: 12px;
    border-bottom: 1px dashed #777777;
    display: block;
    padding-bottom: 10px;
    cursor: pointer;
}

.foot-top-sec h4 {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 40px;
    text-transform: uppercase;
}

.foot-logo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.foot-logo p {
    color: #B6B6B6;
    font-size: 12px;
    line-height: 16px;
}

.newsltr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
    align-items: center;
}

.newsltr span,
.newsltr input {
    background-color: #fff;
    border-radius: 5px 0 0 5px;
    padding: 10px 6px;
    border: 0;
    width: 73%;
}

.newsltr a,
.newsltr button {
    background-color: #8ca720;
    border-radius: 0 5px 5px 0;
    padding:12px 5px;
    border: 0;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    width: 27%;
    margin: 0;
}

/* Footer Sec Css End*/
.galary-sec h3 {
    color: #fff;
    font-size: 32px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 0px;
    padding: 30px 0;
}

.galary-sec h3 span {
    color: #ff5a00;
}

.galary-sec {
    background-color: #12202d;
    border-bottom: 3px solid #ff5a00;
}


#galary_caro.owl-carousel .owl-stage,
#galary_caro.owl-carousel .owl-stage-outer {
    position: inherit;
}

#galary_caro .owl-nav .owl-prev span {
    background: url("../img/galry-aro-l.png") 0 0 no-repeat;
    width: 10px;
    height: 19px;
    display: block;
    font-size: 0;
}

#galary_caro .owl-nav .owl-next span {
    background: url("../img/galry-aro-r.png") 0 0 no-repeat;
    width: 10px;
    height: 19px;
    display: block;
    font-size: 0;
}

#galary_caro .owl-nav .owl-prev {
    position: absolute;
    right: 335px;
    top: -55px;
}

#galary_caro .owl-nav .owl-next {
    position: absolute;
    right: 306px;
    top: -55px;
}

.histry-cont h3 {
    color: #1b292c;
    font-size: 32px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 0px;
    padding: 30px 0;
}

.histry-cont h3 span {
    color: #ff5a00;
}

.histry-cont p {
    color: #3d4e52;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: justify;
    text-align-last: left;
}

.histry-cont li {
    color: #3d4e52;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: justify;
    text-align-last: left;
}

.histry-cont table td {
    color: #3d4e52;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 20px;
}

.history-sec {
    background: #f4f4f4;
    border-bottom: 2px solid #fff;
}

.history-sec-inner {
    display: flex;
    gap: 60px;
}

.histry-cont {
    width: 52%;
    padding-top: 40px;
}

.programmes-sec h3 {
    text-align: center;
    color: #1b292c;
    font-size: 32px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 0px;
    padding: 90px 0;
}

.programmes-cont h4 {
    color: #1b292c;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.programmes-cont p {
    color: #3d4e52;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: justify;
    text-align-last: left;
}

.programmes-sec-inr ul li {
    display: flex;
    gap: 50px;
    margin-bottom: 60px;
}



.programmes-sec-inr ul li:nth-child(2n+2) .programmes-img {
    order: 2;
}

#accreditation_caro .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 20px 0;
}

#accreditation_caro .owl-dots .owl-dot.active span {
    background: #ff5a00;
}

#accreditation_caro .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    background: #d2d0e5;
    border-radius: 50%;
    display: block;
}

.accreditation-sec h3 {
    text-align: center;
    color: #1b292c;
    font-size: 32px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 0px;
    padding: 40px 0;

}

.news-media-sec ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.news-media-sec ul li {
    width: 31%;
    margin-bottom: 40px;
    position: relative;
    cursor: pointer;
}

.news-media-sec ul li img {
    width: 100%;
}

.video-hov {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.45s ease 0s;
    transform: scale(0);
    cursor: pointer;
}

.news-media-sec ul li .video-hov img {
    width: auto;
}

.news-media-sec ul li:hover .video-hov {
    transform: scale(1);
}

.contactus-sec h3 {
    color: #1b292c;
    font-size: 32px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 0px;
    padding: 30px 0;
    text-align: center;
}

.contactus-sec h3 span {
    color: #ff5a00;
}

.map {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

.contact-form input.form-control {
    display: block;
    width: 100%;
    padding: .75rem .75rem .75rem 2.80rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.contact-form textarea.form-area {
    display: block;
    width: 100%;
    padding: .75rem .75rem .75rem 2.80rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-image: url("../img/form-icon4.png");
    background-position: 0 10px;
    background-repeat: no-repeat;
}

.contact-form {
    padding: 40px 0;
}

.btn.btn-subm {
    background: #8ca720;
    padding: 5px 14px;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    font-size: 16px;
}

.btn.btn-subm:hover {
    background: #5fa71f;
    color: #fff;
}

.contact-form input.form-control.name-fld {
    background-image: url("../img/form-icon1.png");
    background-position: 0 10px;
    background-repeat: no-repeat;
}

.contact-form input.form-control.eml-fld {
    background-image: url("../img/form-icon2.png");
    background-position: 0 10px;
    background-repeat: no-repeat;
}

.contact-form input.form-control.phon-fld {
    background-image: url("../img/form-icon3.png");
    background-position: 0 10px;
    background-repeat: no-repeat;
}

.map-cont-com {
    background: #fff;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0 0 10px #ccc;
    width: 100%;
    text-align: center;
    margin: 20px 0;
}

.cont-map {
    width: 58%;
}

.cont-map img {
    width: 100%;
}

.contactus-sec .contactusinnr .container {
    display: flex;
    justify-content: space-between;
}

.map-cont-com span {
    display: block;
    margin-bottom: 30px;
}

.map-cont {
    width: 34%;
}

.map-cont-com a,
.map-cont-com p {
    color: #313e4f;
    font-size: 17px;
    line-height: 20px;
}

.loadr-sec {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

#nest {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 140px;
    width: 140px;
    margin: -25px 0 0 -25px;
    border: 7px solid transparent;
    border-top-color: #ff5a00;
    border-radius: 50%;
    -webkit-animation: spin7 1.5s ease infinite;
    animation: spin7 1.5s ease infinite;

}

#nest span {
    display: block;
    position: absolute;
    top: 44%;
    left: 0px;
    right: 0px;
    height: 20px;
    width: 20px;
    margin: 0 auto;
    border: 4px solid transparent;
    border-top-color: #a5430e;
    border-radius: 50%;
    -webkit-animation: spin7 1.5s ease infinite;
    animation: spin7 2.1s ease infinite;
}

#nest:before {
    content: "";
    position: absolute;
    top: 17px;
    right: 17px;
    bottom: 17px;
    left: 17px;
    border: 5px solid transparent;
    border-radius: 50%;
    border-top-color: #c05d27;
    -webkit-animation: spin7 3s linear infinite;
    animation: spin7 3s linear infinite;
}

#nest:after {
    content: "";
    position: absolute;
    top: 35px;
    right: 35px;
    bottom: 35px;
    left: 35px;
    border: 5px solid transparent;
    border-radius: 50%;
    border-top-color: #dd6e32;
    -webkit-animation: spin7 1.5s ease infinite;
    animation: spin7 1.5s ease infinite;
}

@-webkit-keyframes spin7 {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spin7 {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

button.owl-next:hover,
button.owl-prev:hover {
    background: transparent !important;
}

.inst-base-inner ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.inst-base-inner .histry-cont {
    width: 100%;
    text-align: center;
}

.inst-base-inner .histry-cont p {
    text-align-last: center;
}

.inst-base-inner ul li img {
    width: 100%;
}

.inst-base-inner ul li {
    width: 32%;
    margin-bottom: 20px;
}

.thumbnail strong {
    color: #1b292c;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 14px 10px;
    text-align: center;
    background-color: #eee;
    min-height: 88px;
}

.inst-base-inner ul li strong {
    color: #1b292c;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 14px 0;
    text-align: center;
    background-color: #eee;
    min-height: 69px;
}

.accreditation-sec-inr img {
    width: 100%;
}

.accreditation-sec-inr {
    width: 500px;
    margin: 0 auto;
}

.heig img {
    height: 100%;
}

.cont-map a {
    position: relative;
    height: 20px;
    display: block;
}

.cont-map a.balag {
    top: -423px;
    right: -643px;
    width: 63px;
}

.cont-map a.pandu {
    top: -413px;
    right: -512px;
    width: 50px;
}

.cont-map a.polba {
    top: -334px;
    right: -511px;
    width: 92px;
}

.cont-map a.dhali {
    top: -373px;
    right: -389px;
    width: 80px;
}

.thumbnail img {
    width: 100%;
}

/* Responsive Css */
@media screen and (max-width:944px) {
    #cssmenu ul {
        width: 300px;
        display: none;
        position: absolute;
        background: #fff;
        right: 0;
        top: 50px;
    }

    #cssmenu.align-center>ul,
    #cssmenu.align-right ul ul {
        text-align: left;
    }

    #cssmenu ul li,
    #cssmenu ul ul li,
    #cssmenu ul li:hover>ul>li {
        width: 100%;
        height: auto;
        border-top: 1px solid rgba(120, 120, 120, 0.15);
    }

    #cssmenu ul li a,
    #cssmenu ul ul li a {
        width: 100%;
        color: #191818;
    }

    #cssmenu>ul>li>a.active,
    #cssmenu>ul>li>a:hover {
        color: #fff;
        background-color: #ff5a00;
    }

    #cssmenu>ul>li,
    #cssmenu.align-center>ul>li,
    #cssmenu.align-right>ul>li {
        float: none;
        display: block;
    }

    #cssmenu ul ul li a {
        padding: 10px 10px 10px 20px;
        color: #fff;
        background: #d9442a;
        font-size: 14px;
    }

    #cssmenu ul ul li:hover>a,
    #cssmenu ul ul li a:hover {
        color: #191818;
    }

    #cssmenu ul ul ul li a {
        padding-left: 40px;
    }

    #cssmenu ul ul,
    #cssmenu ul ul ul {
        position: relative;
        left: 0;
        right: auto;
        width: 100%;
        margin: 0;
        background: #969696;
        top: 0;
    }

    #cssmenu>ul>li.has-sub>a::after,
    #cssmenu ul ul li.has-sub>a::after {
        display: none;
    }

    #menu-line {
        display: none;
    }

    #cssmenu #menu-button {
        display: block;
        padding: 10px;
        color: #191818;
        cursor: pointer;
        text-transform: uppercase;
        background: #fff;
        width: 40px;
        margin-left: auto;
        height: 40px;
    }

    #cssmenu #menu-button::after {
        content: '';
        position: absolute;
        top: 9px;
        right: 7px;
        display: block;
        width: 27px;
        height: 2px;
        background: #191818;
        transition: all 0.45s ease 0s;
    }

    #cssmenu #menu-button::before {
        content: '';
        position: absolute;
        top: 19px;
        right: 7px;
        display: block;
        width: 27px;
        height: 12px;
        border-top: 2px solid #191818;
        border-bottom: 2px solid #191818;
        transition: all 0.45s ease 0s;
    }

    #cssmenu #menu-button.menu-opened::after {
        transform: rotate(45deg);
        top: 21px;
        right: 6px;
    }

    #cssmenu #menu-button.menu-opened::before {
        border-top: 0;
        transform: rotate(138deg);
        top: 20px;
        right: 3px;
    }

    #cssmenu .submenu-button {
        position: absolute;
        z-index: 10;
        right: 0;
        top: 0;
        display: block;
        border-left: 1px solid rgba(120, 120, 120, 0.15);
        height: 40px;
        width: 40px;
        cursor: pointer;
    }

    #cssmenu .submenu-button::after {
        content: '';
        position: absolute;
        top: 15px;
        left: 20px;
        display: block;
        width: 1px;
        height: 11px;
        background: #191818;
        z-index: 99;
    }

    #cssmenu .submenu-button::before {
        content: '';
        position: absolute;
        left: 15px;
        top: 20px;
        display: block;
        width: 11px;
        height: 1px;
        background: #191818;
        z-index: 99;
    }

    #cssmenu .submenu-button:hover::before,
    #cssmenu .submenu-button:hover::after {
        background: #000;
    }

    #cssmenu .submenu-button.submenu-opened:after {
        display: none;
    }

    .ban-cont strong {
        font-size: 26px;
        line-height: 36px;
    }

    .top-nav .col-md-6.col-sm-6 {
        width: inherit;
    }

    .top-nav .row {
        justify-content: space-between;
    }

    .socl-top ul {
        gap: 3px;
    }

    .ban-cont {
        bottom: 15%;
        width: 92%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .ban-cont p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 0px;
    }

    .catgry-sec-DIV {
        width: 50%;
    }

    .video-sec-content p {
        font-size: 20px;
        line-height: 28px;
        margin: 10px auto;
        width: 90%;
    }

    .tstimo-sec-blo {
        padding: 0;
    }

    .tstimo-sec-blo-left {
        width: 100%;
        padding: 20px 0;
        margin: 0;
    }

    .tstimo-sec-blo-rit {
        width: 100%;
        position: relative;
    }

    .tstimo-sec-txt {
        padding: 20px;
        width: 100%;
    }

    .tstimo-sec-blo-left h3 {
        font-size: 40px;
        color: #000;
        line-height: 46px;
        margin-bottom: 10px;
    }

    .tstimo-sec-blo-left h3 span {
        color: #000;
        font-size: 20px;
        line-height: 22px;
    }

    .footer-sec_inr {
        display: block;
    }

    .foot_mnu_l,
    .logo-foot {
        width: 100%;
    }

    .foot_mnu {
        width: 100%;
        margin: 20px 0;
    }

    .foot-top-sec {
        padding: 20px 0;
    }

    .tstimo-sec-form .col-md-8.col-sm-12 {
        margin-top: 20px;
    }

    .tstimo-sec-blo .container {
        display: block;
    }

    .tstimo-sec-blo-rit h4 {
        font-size: 28px;
    }

    .programmes-sec-inr ul li {
        display: block;
        margin-bottom: 10px;
    }

    .programmes-img img {
        width: 100%;
    }

    .programmes-img {
        margin-bottom: 10px;
    }

    .history-sec-inner {
        display: block;
    }

    .histry-cont {
        width: 100%;
        padding-top: 0;
    }

    .history-sec .container-fluid.px-0 {
        padding: 0 15px !important;
    }

    .orgns-div img {
        width: 100%;
    }

    .orgns-div {
        padding-top: 20px;
    }

    .contactus-sec .contactusinnr .container {
        display: block;
    }

    .cont-map {
        width: 100%;
    }

    .map-cont {
        width: 100%;
        padding-top: 20px;
    }

    .contact-form {
        padding-top: 0;
    }
}


@media screen and (max-width:600px) {
    .catgry-sec-DIV {
        width: 100%;
        margin: 10px 0;
    }

    .logo img {
        width: 70px;
    }

    .header_iner {
        padding: 4px 0;
    }

    /* .video-sec .tile__media .tile__img {
        width: 34px;
    } */

    .video-sec-content p {
        font-size: 14px;
        line-height: 20px;
    }

    .video-sec-content {
        top: 26%;
    }

    .copy-rit-socl .container {
        display: block;
        text-align: center;
    }

    .foot-socl {
        justify-content: center;
        margin: 10px auto;
        order: 1;
    }

    .tstimo-sec-blo-rit span {
        position: absolute;
        right: 60px;
        bottom: 0;
        top: 50px;
    }

    .news-media-sec .thumbnail img {
        width: 100%;
    }

    .foot_mnu_l ul li {
        width: 90%;
    }

    .news-media-sec .col-md-3 {
        margin-bottom: 5px;
    }

    .map-cont-com {
        padding: 10px;
        width: 100%;
        margin: 0 0 15px;
    }

    .map-cont {
        display: block;
    }

    .contact-form .row.pb-5 {
        padding-bottom: 0 !important;
    }

    .logo a {
        width: 160px;
    }

    .accreditation-sec .w-50.mx-auto {
        width: 100% !important;
    }

    #tstimo_caro .owl-nav {
        position: relative;
        right: 0;
        bottom: 0;
    }

    #tstimo_caro {
        padding: 20px 20px;
    }

    .media-news-left {
        width: 100%;
        padding: 10px;
        text-align: center;
    }

    .media-news-inner {
        display: block;
    }

    #media_caro {
        width: 100%;
    }

    #media_caro .owl-nav {
        bottom: inherit;
        top: -52px;
        right: 0;
        left: 0;
    }

    .video-play-button::after,
    .video-play-button::before {
        width: 40px;
        height: 40px;
    }

    .video-play-button span {
        border-left: 10px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        left: 0;
        top: 14px;
        right: 0;
        margin: 0 auto;
    }

    .video-play-button {
        padding: 0;
    }

    .video-sec-content p {
        font-size: 10px !important;
        line-height: 14px !important;
        margin: 10px auto;
        width: 90%;
    }

    .video-sec-content .btn.btn-redmor {
        padding: 2px 14px;
        font-size: 10px;
    }

    .hom-vdeo-body.modal-body video {
        width: 100%;
        height: auto;
    }

    .hom-vdeo.modal-header {
        border: 1px solid #fff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }

    .hom-vdeo.modal-header .btn-close {
        padding: 0;
        filter: brightness(0) invert(1);
        font-size: 18px;
        background-size: 10px;
        width: 10px;
        height: 10px;
        margin: 0 auto;
    }

    #cssmenu>ul>li>a {
        padding: 0px 22px;
        font-size: 14px;
        line-height: 40px;
    }

    .media-news-DIV strong {
        min-height: auto;
    }

    .histry-img img {
        width: 100%;
    }

    .catgry-sec-DIV.img-Div img {
        height: auto;
    }

    .inst-base-inner ul li {
        width: 100%;
        margin-bottom: 20px;
    }

    .accreditation-sec-inr {
        width: 100%;

    }

    .accreditation-sec.pb-5 {
        padding-bottom: 0 !important;
    }

    .contact-form .row.mb-5 {
        margin-bottom: 0 !important;
    }

    .contact-form .row.pb-5 .col-md-4.col-sm-12.position-relative,
    .contact-form .row.mb-5 .col-md-4.col-sm-12.position-relative {
        margin-bottom: 10px !important;
    }

    .contact-form .btn.btn-subm {
        margin-top: 10px;
    }

    .cont-map a.balag {
        top: -263px;
        right: -390px;
        width: 43px;
    }

    .cont-map a.pandu {
        top: -264px;
        right: -308px;
        width: 40px;
    }

    .cont-map a.polba {
        top: -224px;
        right: -307px;
        width: 64px;
    }

    .cont-map a.dhali {
        top: -254px;
        right: -235px;
        width: 55px;
    }

    .foot_mnu_l:nth-child(2) ul li:nth-child(5),
    .foot_mnu_l:nth-child(2) ul li:nth-child(6) {
        display: none;
    }
}

@media screen and (max-width:500px) {
    .cont-map a {
        display: none;
    }
}